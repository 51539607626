
$lightblue: #74e0fd;
$blue: #4641d4;
$purple: #733dff;
$green: #3abe87;
$red: #b25a7c;
$yellow: #f4ff97;

html {
  font-size: 18px;

  @media only screen and (max-width: 800px) {
    font-size: 16px;
  }

  @media only screen and (max-width: 750px) {
    font-size: 14px;
  }

  @media only screen and (max-width: 700px) {
    font-size: 12px;
  }

  @media only screen and (max-width: 600px) {
    font-size: 10px;
  }

  @media only screen and (max-width: 500px) {
    font-size: 8px;
  }

  @media only screen and (max-width: 400px) {
    font-size: 7px;
  }

  @media only screen and (max-width: 330px) {
    font-size: 6px;
  }
}

body {
  margin: 0;
  font-family: monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: black;
  padding: 50px 15px;
}

header,
footer {
  background: $purple;
  color: white;
  position: absolute;
  left: 0;
  right: 0;
  border-radius: 5px;
}

header {
  top: 0;
  padding: 10px;
  font-size: 2rem;
}

footer {
  bottom: 0;
  border-radius: 5px;

  > a {
    text-decoration: none;
    padding: 5px;
    font-size: 1rem;
    color: white;
    text-align: center;
    display: block;
  }
}

button {
  border: none;
  border-radius: 5px;
  background: $purple;
  color: $lightblue;
  padding: 2rem;
  margin-top: 25px;
  text-transform: uppercase;
  font-family: inherit;
  font-size: 1.3rem;

  &:focus {
    outline: none !important;
  }
}

.intro {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);

  > h1 {
    color: $lightblue;
    font-size: 3.2rem;
  }

  > button {
    font-size: 2.5rem;
    padding: 15px 25px;
    font-weight: bold;
  }

  > .info {
    font-size: 1.5rem;
    margin-top: 20vh;
  }
}

p {
  margin: 0;
  font-size: 3.5rem;
  color: white;

  &.large {
    font-size: 10rem;
  }

  &.green {
    color: $green;
  }

  &.lightblue {
    color: $lightblue;
  }

  &.blue {
    color: $blue;
  }

  &.red {
    color: $red;
  }

  &.yellow {
    color: $yellow;
  }
}

.row {
  display: flex;
}

.col {
  flex: 1;
}
